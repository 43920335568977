// third-party
import { createSlice } from '@reduxjs/toolkit';
import { toInteger, unset } from 'lodash';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { setGlobalLoading } from './globalSlice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    customers: [],
    orders: [],
    cargando: false,
    products: [],
    product: {},
    categories: [],
    bancos: [],
    options: [],
    items: [],
    subcategories: [],
    productreviews: [],
    optionsProduct: [],
    ventasProductos: [],
    status: 0,
    estadoFiltro: 'todos',
    productosRegalo: [],
    cargandoProducto: false,
    cargandoProductos: false,
    cargandoOpciones: false,
    productoSucursales: [],
    importando: false,
    dataImport: '',
    opcionesSucursales: [],
    cargandoOpcion: false
};

const slice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CUSTOMERS
        getCustomersSuccess(state, action) {
            state.customers = action.payload;
        },

        // GET ORDERS
        getOrdersSuccess(state, action) {
            state.orders = action.payload;
        },

        // GET CATEGORIES
        getCategoriesSuccess(state, action) {
            state.categories = action.payload;
        },

        getBancosSuccess(state, action) {
            state.bancos = action.payload;
        },

        // LIMPIAR CATEGORIES
        limpiarCategoriesSuccess(state) {
            state.categories = [];
        },

        // LIMPIAR Items
        limpiarItemsSuccess(state) {
            state.items = [];
        },

        limpiarDataImportSuccess(state) {
            state.dataImport = '';
        },

        // LIMPIAR Productos
        limpiarProductsSuccess(state) {
            state.products = [];
        },

        // GET SUBCATEGORIES
        getSubCategoriesSuccess(state, action) {
            state.subcategories = action.payload;
        },

        // SET CATEGORIES
        setStatusSuccess(state, action) {
            state.status = action.payload;
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.products = action.payload;
        },

        // GET PRODUCTS SUCURSALES
        getProductoSucursalesSuccess(state, action) {
            state.productoSucursales = action.payload;
        },

        getOpcionesSucursalesSuccess(state, action) {
            state.opcionesSucursales = action.payload;
        },

        // GET OPTIONS
        getOptionsSuccess(state, action) {
            state.options = action.payload;
        },

        // GET OPTIONS PRODUCTS
        getProductOptionsSuccess(state, action) {
            state.optionsProduct = action.payload;
        },

        // CLEAN OPTIONS PRODUCTS
        cleanProductOptionsSuccess(state) {
            state.optionsProduct = [];
        },

        // GET ITEMS
        getItemsSuccess(state, action) {
            state.items = action.payload;
        },

        // GET ITEMS
        getDataImportSuccess(state, action) {
            state.dataImport = action.payload;
        },

        getProductSuccess(state, action) {
            state.product = action.payload;
        },

        createStatusReset(state) {
            state.status = 0;
        },

        // GET PRODUCT REVIEWS
        getProductReviewsSuccess(state, action) {
            state.productreviews = action.payload;
        },

        getVentasProductosSuccess(state, action) {
            // ordenar de mayor a menor por venta_producto
            state.ventasProductos = action.payload.sort((a, b) => toInteger(b.cantidad) - toInteger(a.cantidad));
        },

        setEstadoFiltroSuccess(state, action) {
            state.estadoFiltro = action.payload;
        },
        // GET OPTIONS PRODUCTS
        getRegalosSuccess(state, action) {
            state.regalos = action.payload;
        },
        setProductosRegaloSuccess(state, action) {
            state.productosRegalo = action.payload;
        },
        setCargandoSuccess(state) {
            state.cargando = true;
        },
        unsetCargandoSuccess(state) {
            state.cargando = false;
        },
        setCargandoProductoSuccess(state) {
            state.cargandoProducto = true;
        },
        unsetCargandoProductoSuccess(state) {
            state.cargandoProducto = false;
        },
        setCargandoProductosSuccess(state) {
            state.cargandoProductos = true;
        },
        unsetCargandoProductosSuccess(state) {
            state.cargandoProductos = false;
        },
        setCargandoOpcionesSuccess(state) {
            state.cargandoOpciones = true;
        },
        unsetCargandoOpcionesSuccess(state) {
            state.cargandoOpciones = false;
        },
        setImportandoSuccess(state) {
            state.importando = true;
        },
        unsetImportandoSuccess(state) {
            state.importando = false;
        },
        setCargandoOpcionSuccess(state) {
            state.cargandoOpcion = true;
        },
        unsetCargandoOpcionSuccess(state) {
            state.cargandoOpcion = false;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function cleanProductOptions() {
    return async () => {
        try {
            dispatch(slice.actions.cleanProductOptionsSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createStatus() {
    return async () => {
        try {
            dispatch(slice.actions.createStatusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargandoOpcion() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoOpcionSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargandoOpcion() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoOpcionSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargandoOpciones() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoOpcionesSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargandoOpciones() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoOpcionesSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setImportando() {
    return async () => {
        try {
            dispatch(slice.actions.setImportandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetImportando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetImportandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargando() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargando() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargandoProducto() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoProductoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargandoProducto() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoProductoSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setCargandoProductos() {
    return async () => {
        try {
            dispatch(slice.actions.setCargandoProductosSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unsetCargandoProductos() {
    return async () => {
        try {
            dispatch(slice.actions.unsetCargandoProductosSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOrders() {
    return async () => {
        try {
            const response = await axios.get('/api/customer/order/list');
            dispatch(slice.actions.getOrdersSuccess(response.data.orders));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCategories(idNegocio) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getCategorias`, {
                params: {
                    id: idNegocio
                }
            });
            dispatch(slice.actions.getCategoriesSuccess(response.data.data));
            dispatch(slice.actions.createStatusReset());
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBancos() {
    return async () => {
        try {
            dispatch(setCargando());
            const { data } = await axios.get(`/getBancos`);
            dispatch(slice.actions.getBancosSuccess(data.bancos));
            dispatch(slice.actions.createStatusReset());
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarCategories() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarCategoriesSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarItems() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarItemsSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarDataImport() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarDataImportSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function limpiarProductos() {
    return async () => {
        try {
            dispatch(slice.actions.limpiarProductsSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filtrarCategories(estado, idNegocio) {
    return async () => {
        dispatch(setCargando());
        try {
            const response = await axios.get(`/getCategorias`, {
                params: {
                    id: idNegocio
                }
            });

            const categoriasFiltradas = response.data.data.filter((categorie) => categorie.estado === estado);
            dispatch(slice.actions.getCategoriesSuccess(categoriasFiltradas));
            dispatch(slice.actions.createStatusReset());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

// obtener subcategorias
export function getSubCategories(idCategoria, idNegocio) {
    return async () => {
        try {
            const response = await axios.get(`/getSubcategorias`, {
                params: {
                    id_categoria: idCategoria,
                    id_negocio: idNegocio
                }
            });
            dispatch(slice.actions.getSubCategoriesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStatusCategory(data) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post(`/updateEstadoCategoria`, data);
            dispatch(filtrarCategories(data.estado, data.idNegocio));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

// crear categoria
export function createCategory(data) {
    return async () => {
        try {
            const response = await axios.post(`/agregarCategoria`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// editar categoria
export function updateCategory(data) {
    return async () => {
        try {
            const response = await axios.post(`/editarCategoria`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProducts(filtro) {
    return async () => {
        try {
            dispatch(setCargandoProductos());
            const response = await axios.get(`/getProductos`, {
                params: {
                    estado: filtro?.estadoBusqueda !== -1 ? filtro?.estadoBusqueda : 'todos',
                    busqueda: filtro?.busqueda
                }
            });
            dispatch(slice.actions.getProductsSuccess(response.data.data));
            dispatch(unsetCargandoProductos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductsCategories(idCategoria) {
    return async () => {
        try {
            dispatch(setCargandoProductos());
            const response = await axios.get(`/getProductosCategories`, {
                params: {
                    id_categoria: idCategoria
                }
            });
            dispatch(slice.actions.getProductsSuccess(response.data.data));
            dispatch(unsetCargandoProductos());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProduct(idProducto) {
    return async () => {
        try {
            const response = await axios.get(`/getProducto`, {
                params: {
                    id: idProducto
                }
            });
            dispatch(slice.actions.getProductSuccess(response.data.data.sucursales));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Crear producto
export function createProduct(data) {
    return async () => {
        try {
            dispatch(setCargandoProductos());
            const response = await axios.post(`/agregarProducto`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargandoProducto());
        }
    };
}

// Editar producto
export function updateProduct(data) {
    return async () => {
        try {
            dispatch(setCargandoProducto());
            const response = await axios.post(`/editarProducto`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargandoProducto());
        }
    };
}

export function updateStatusProduct(data) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post(`/updateEstadoProducto`, data);
            dispatch(slice.actions.getProductsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function deleteProduct(idProduct) {
    return async () => {
        if (!idProduct) {
            throw new Error('El ID del producto es requerido');
        }
        dispatch(setCargandoProductos());
        try {
            const response = await axios.post(`/deleteProduct`, { id_product: idProduct });
            dispatch(slice.actions.getProductsSuccess(response.data.data));
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error al eliminar el producto';
            dispatch(slice.actions.hasError(errorMessage));
        } finally {
            dispatch(unsetCargandoProductos());
        }
    };
}

export function importProducts(data) {
    return async () => {
        dispatch(setImportando());
        dispatch(limpiarDataImport());
        dispatch(slice.actions.createStatusReset());
        try {
            const formData = new FormData();
            formData.append('file', data.file);

            const response = await axios.post(`/importarProductos`, formData);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
            dispatch(slice.actions.getDataImportSuccess(response.data.data));
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error al importar productos';
            dispatch(slice.actions.hasError(errorMessage));
            dispatch(slice.actions.setStatusSuccess(error.response?.status || 500));
        } finally {
            dispatch(unsetImportando());
        }
    };
}

export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/customer/product/reviews');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productreviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOptions(idNegocio) {
    dispatch(cleanProductOptions);
    return async () => {
        try {
            dispatch(setCargandoOpciones());
            const response = await axios.get(`/getOpciones`, {
                params: {
                    negocio_id: idNegocio
                }
            });
            dispatch(slice.actions.getOptionsSuccess(response.data.data));
            dispatch(slice.actions.createStatusReset());
            dispatch(unsetCargandoOpciones());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Crear opcion
export function createOption(data) {
    return async () => {
        try {
            dispatch(setCargandoOpcion());
            const response = await axios.post(`/agregarOpcion`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargandoOpcion());
        }
    };
}

// Editar opcion
export function updateOption(data) {
    return async () => {
        try {
            dispatch(setCargandoOpcion());
            const response = await axios.post(`/editarOpcion`, data);
            dispatch(slice.actions.setStatusSuccess(response.data.code));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargandoOpcion());
        }
    };
}

// editar estado opcion
export function updateStatusOption(data) {
    return async () => {
        dispatch(setGlobalLoading(true));
        try {
            const response = await axios.post(`/updateEstadoOpcion`, data);
            dispatch(slice.actions.getOptionsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
}

export function updateStatusItem(data) {
    return async () => {
        try {
            const response = await axios.post(`/updateEstadoItem`, data);
            dispatch(slice.actions.getItemsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateItems(data) {
    return async () => {
        try {
            const response = await axios.post(`/updateEstadoItems`, data);
            dispatch(slice.actions.getItemsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Obtener items de una opcion
export function getItems(idOpcion) {
    dispatch(slice.actions.getItemsSuccess([]));
    return async () => {
        dispatch(setCargandoOpciones());
        try {
            const response = await axios.get(`/getItemsOpciones`, {
                params: {
                    id: idOpcion
                }
            });
            dispatch(slice.actions.getItemsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(unsetCargandoOpciones());
        }
    };
}

// obtener opciones de producto
export function getOptionsProduct(idProducto) {
    return async () => {
        try {
            const response = await axios.get(`/getOpcionesProducto`, {
                params: {
                    id: idProducto
                }
            });
            dispatch(slice.actions.getProductOptionsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getVentasProductos(data) {
    return async () => {
        try {
            const response = await axios.get(`/getVentasProductos`, {
                params: {
                    negocio_id: data.id_negocio,
                    fecha_inicio: data.fecha_inicio,
                    fecha_fin: data.fecha_fin
                }
            });
            dispatch(slice.actions.getVentasProductosSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setEstadoFiltro(data) {
    return async () => {
        try {
            dispatch(slice.actions.setEstadoFiltroSuccess(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// obtener regalos
export function getRegalos() {
    return async () => {
        dispatch(setCargando());
        try {
            const response = await axios.get(`/getCuponesRegalosPorSucursal`);
            dispatch(slice.actions.getRegalosSuccess(response.data.product));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductsRegalos() {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getProductosRegalo`, {
                params: {
                    estado: 'todos',
                    es_regalo: 1
                }
            });
            dispatch(slice.actions.setProductosRegaloSuccess(response.data.data));
            dispatch(unsetCargando());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductosSucursales(idProducto) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getProductosSucursales`, {
                params: {
                    productoId: idProducto
                }
            });
            dispatch(slice.actions.getProductoSucursalesSuccess(response.data.data));
        } catch (error) {
            const mensaje = error.response?.data?.message || 'Error al obtener sucursales del producto';
            dispatch(slice.actions.hasError(mensaje));
        } finally {
            dispatch(unsetCargando());
        }
    };
}

export function getOpcionesSucursales(idOpcion) {
    return async () => {
        try {
            dispatch(setCargando());
            const response = await axios.get(`/getOpcionesSucursales`, {
                params: {
                    opcionId: idOpcion
                }
            });
            dispatch(slice.actions.getOpcionesSucursalesSuccess(response.data.data));
        } catch (error) {
            const mensaje = error.response?.data?.message || 'Error al obtener sucursales de la opcion';
            dispatch(slice.actions.hasError(mensaje));
        } finally {
            dispatch(unsetCargando());
        }
    };
}
